.collapseWrapper {
  width: 100%;
  height: max-content;
  padding: 10px;
  background-color: #F9F9F9;
}


.overwriteCollapse {
  display: flex;
  flex-direction: column;
  gap: 6px;
  border: none;
  background-color: #F9F9F9;
}

.overwritePanel {
  background-color: #FFFFFF;
  border-bottom: none !important;
}

.customHeader {
  display: flex;
  align-items: center;
  min-height: 48px;
  background-color: #FFFFFF;
  margin-bottom: 6px;
  row-gap: 0px;
  align-items: center;
  padding: 4px 38px;
}

.headerItem {
  font-family: 'Barlow';
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 150%;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  color: #161616;
  opacity: 0.6;
}
