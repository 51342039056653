.indicatorSuccess {
  border: 0;
  height: 15px;
  width: 15px;
  border-radius: 50%;
  background-color: green
}

.indicatorNegative {
  border: 0;
  height: 15px;
  width: 15px;
  border-radius: 50%;
  background-color: red
}
