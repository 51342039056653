.mobile-menu-wrapper {
  position: fixed;
  inset: 0;
  background: white;
  z-index: 999;
  overflow-y: auto;
}

.mobile-menu-close-button {
  border: 0;
  width: auto;
}

.mobile-menu-close-button:hover {
  border: 0;
}
