.desc {
  width: 205px;
  min-height: 257px;
  background: #ffffff;
  border-radius: 8px;
  padding: 24px 30px;
}

.avatar {
  width: 140px;
  height: 140px;
  margin-bottom: 10px;
}

.descTitle,
.descSubtitle {
  margin-bottom: 0;
}

.descTitle {
  font-family: Barlow;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  margin-top: 15px;

  color: #161616;
}

.descSubtitle {
  margin-top: 6px;
  font-family: Barlow;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  /* identical to box height, or 143% */

  text-decoration-line: underline;

  color: #615293;
}

.nav {
  margin-top: 16px;
  padding: 18px 0;
  width: 205px;
  background: #ffffff;
  border-radius: 8px;
  overflow: hidden;
}

.mainBar {
  background: #ffffff;
  width: 100%;
}

.titleBar {
  background: #fcfcfc;
  min-height: 56px;
  padding: 18px 24px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.title {
  font-family: Barlow;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  margin: 0;
  color: #1a1a1a;
}

.content {
  background-color: #fff;
  min-height: 420px;
  border: 1px solid #fff;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  padding: 24px;
}

@media screen and (max-width: 590px) {
  .desc {
    min-height: 150px;
    padding: 10px 30px;
  }

  .descContainer {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .avatar {
    width: 100px;
    height: 100px;
    margin-bottom: 0;
  }

  .descTitle {
    font-size: 14px;
    line-height: 17px;
    margin-top: 0;
    margin-left: 10px;
    text-align: left;
  }

  .descSubtitle {
    font-size: 12px;
    line-height: 18px;
    margin-left: 10px;
  }

  .nav {
    padding: 0;
  }

  .mainBar {
    margin-left: 0;
  }

  .titleBar {
    background: transparent;
    border-top: 1px solid #000;
    border-bottom: 1px solid #000;
    border-radius: 0;
  }

  .title {
    margin-bottom: 15px;
  }

  .barBtns {
    width: 100%;
  }
}
