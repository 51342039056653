@import url("../../../styles/variables.css");

.wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.input {
    width: 100%;
    max-height: 48px;
    margin-bottom: 12px;
    padding: 14px 16px;
    outline: none;
    background: #ffffff;
    border: 1px solid #ededed;
    border-radius: 4px;
}

.label {
    margin-bottom: 8px;
    font-size: 14px;
    line-height: 20px;
}

.required {
    margin-left: 3px;
    color: var(--custom-warning-color);
}

.required.isValid {
    color: #646464;
}

.input::placeholder {
    color: #161616;
    opacity: 0.3;
    font-size: 14px;
    line-height: 20px;
}


.editableInputContainer {
    position: relative;
    width: 100%;
}

.editableInput {
    background-color: transparent;
    border: 1px solid #A78BFA;
    border-radius: 4px;
    color: #A78BFA;
    outline: 0;
    padding: 0.25rem 0.5rem;

}

.editableInputButtonsContainer {
    display: flex;
    position: absolute;
    top: calc(100% + 5px);
    right: 0;
    justify-content: flex-end;
    gap: 0 10px;
    width: 100%;
}

.editableInputCheckButton {
    border: 1px solid var(--custom-success-color);
    text-align: center;
    border-radius: 4px;
    background: var(--custom-success-color);
    cursor: pointer;
    padding: 3px 6px;
    color: white;
}

.editableInputCheckButton:hover {
    background-color: transparent;
    color: var(--custom-success-color);
}

.editableInputDeleteButton {
    text-align: center;
    padding: 3px 6px;
    border: 1px solid var(--custom-warning-color);
    color: white;
    border-radius: 4px;
    cursor: pointer;
    background: var(--custom-warning-color);
}

.editableInputDeleteButton:hover {
    background-color: transparent;
    color: var(--custom-warning-color)
}


.editableInputTextButton {
    display: inline-block;
    color: var(--custom-primary-color);
    border-radius: 4px;
    padding: 0.25rem 0.5rem;
    background-color: transparent;
    border: 1px solid var(--custom-primary-color);
    outline: 0;
    cursor: pointer;
}


/*regular button styles*/

.regularEditableInput {
    background-color: transparent;
    border: 1px solid #A78BFA;
    border-radius: 4px;
    color: #A78BFA;
    outline: 0;
    padding: 0.25rem 0.5rem;
    width: 100%;
}

.regularEditableInputButtonsContainer {
    display: flex;
    gap: 0 10px;
    justify-content: flex-end;
    left: 0;
    position: absolute;
    right: 0;
    top: calc(100% + 5px);
    width: 100%;
}

.regularEditableInputTextButton {
    background-color: transparent;
    border: 1px solid var(--custom-primary-color);
    border-radius: 4px;
    color: var(--custom-primary-color);
    cursor: pointer;
    display: inline-block;
    outline: 0;
    padding: 0.25rem 0.5rem;
    text-align: left;
    width: 100%;
}
