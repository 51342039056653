.button {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 9px 16px;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  border: 0;
  cursor: pointer;
  transition: all 200ms;
}

.default {
  background: transparent;
  border: 1px solid #f2f0f0;
  border-radius: 4px;
}

.default:hover {
  border: 1px solid #ddd;
}

.icon {
  margin-left: 11px;
}

.primary {
  background: #5552ff;
  border-radius: 4px;
  color: #ffffff;
  border: 1px solid #5552ff;
}

.primary:hover {
  background: #3e3bfe;
  border: 1px solid #3e3bfe;
}

.warning {
  background: #ff728b;
  border-radius: 4px;
  color: #ffffff;
  border: 1px solid #ff728b;
}

.warning:hover {
  background: #fa4d6d;
  border: 1px solid #fa4d6d;
}

.danger {
  background: #ffce72;
  border-radius: 4px;
  color: #ffffff;
  border: 1px solid #ffc55a;
}

.danger:hover {
  background: #fdae1d;
  border: 1px solid #ffce72;
}

.link {
  width: auto;
  color: #acb0b7;
  background: none;
  padding: 0;
}

.link:hover {
  color: #8993a4;
  text-decoration: underline;
}

.button:disabled {
  background: #f2f0f0;
  color: #3e3bfe71;
  cursor: not-allowed;
}

.link:disabled {
  background: transparent;
}
