@media screen and (max-width: 877px) {
  .menu-button {
    display: block;
    border: 0 none;
    margin-right: 7px;
    padding: 8px;
    background: #f8f8f8;
    width: auto;
  }

  .menu-button:hover {
    border: 0;
  }

  .profile-information-responsive {
    display: none;
  }

  .shared-main-nav-wrapper {
    display: none;
  }
}

@media screen and (max-width: 450px) {
  .page-title {
    font-size: 18px;
    line-height: 18px;
  }
}
