.bar {
  position: relative;
  display: flex;
  align-items: center;
  width: 100px;
  height: 24px;
  border-radius: 4px;
  background: #f8f8f8;
}

.text {
  position: relative;
  padding: 5px;
}

.first,
.second,
.third,
.fourth {
  position: relative;
}

.first.inner {
  content: '';
  position: absolute;
  left: 0;
  height: 100%;
  background: #ffe27b;
}

.second.inner {
  content: '';
  position: absolute;
  left: 0;
  height: 100%;
  background: #ffc98a;
}

.third.inner {
  content: '';
  position: absolute;
  left: 0;
  height: 100%;
  background: #c3f9b2;
}

.fourth.inner {
  content: '';
  position: absolute;
  left: 0;
  height: 100%;
  background: #b0d9ff;
}
