.feedbackItemContainer {
    border-bottom: 1px solid #F8F8F8;
    margin: 10px 0;
    padding: 16px 0;
}

.feedbackItemAvatar {
    cursor: pointer;
    margin-right: 7px;
}

.feedbackItemUsername {
    font-weight: bold;
    line-height: 1;
    margin: 0;
}

.feedbackItemDate {
    color: #161616;
    font-size: 12px;
    line-height: 14px;
    margin-left: auto;
    opacity: 0.4;
}

.feedbackItemContent {
    margin: 10px 0 0 0;
}

.feedbackComponentHeader {
    border: 1px solid #F8F8F8;
    padding: 12px;
    width: 336px;
}

.feedbackComponentHeaderName {
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    margin-bottom: 6px;
}

.feedbackComponentHeaderEmail {
    color: rgba(22, 22, 22, 0.42);
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 0;
}

.feedbackComponentItemsWrapper {
    height: 55vh;
    margin-top: 16px;
    overflow-y: auto;
    width: 100%;
}

.feedbackComponentFormWrapper {
    background: white;
    border-top: 1px solid #F8F8F8;
    bottom: 0;
    margin-top: auto;
    padding: 16px 0;
    position: fixed;
    width: 100%;
}
