.documentBorder {
  width: 100%;
  border: 1px solid #C4C4C4;
  box-shadow: inset 0 8px 10px rgba(0, 0, 0, 0.07);
  border-radius: 10px;
  background: #FFFFFF;
  box-sizing: border-box;
  padding: 20px 0;
}

.documentWrapper {
  user-select: none;
  width: 60%;
  overflow: auto;
  height: 440px;
}

.pdfActionsWrapper {
  position: relative;
  margin-top: 5px;
  width: 100%;
}

.pdfPaginationWrapper {
  border: 1px solid #F2F2F2;
  border-radius: 10px;
}

@media screen and (max-width: 877px) {
  .documentWrapper {
    width: calc(100% - 20px);
  }

}
