.applicantTable table {
  border-collapse: collapse;
}

.applied_only,
.applied_only td {
  background-color: #faf1d7;
}

.exam_scheduled,
.exam_scheduled td {
  background-color: #e9f5ff;
}

.reschedule_the_exam,
.reschedule_the_exam td {
  background-color: #f1f0ef;
}

.exam_passed,
.exam_passed td {
  background-color: #e1e8ea;
}

.exam_waitlist,
.exam_waitlist td {
  background-color: #f2efdd;
}

.exam_failed,
.exam_failed td {
  background-color: #f5e5e5;
}

.interview_absent,
.interview_absent td {
  background-color: #f4fbff;
}

.interview_scheduled,
.interview_scheduled td {
  background-color: #e0ecf0;
}

.interview_waitlist,
.interview_waitlist td {
  background-color: #f2efdd;
}

.interview_failed,
.interview_failed td {
  background-color: #f5e9e9;
}

.interview_passed,
.interview_passed td {
  background-color: #f0f8eb;
}

.enrolled,
.enrolled td {
  background-color: #cce7e1;
}

.redirected,
.redirected td {
  background-color: #e3e1e0;
}

.drop_out,
.drop_out td {
  background-color: #ebdfdf;
}

.graduated,
.graduated td {
  background-color: #fff2df;
}

.blacklist,
.blacklist td {
  background-color: #d5d5d5;
}

.consider_for_next_course,
.consider_for_next_course td {
  background-color: #eeeef9;
}
