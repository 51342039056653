.description {
  width: 70%;
  margin: 0 auto 24px auto;
}

@media screen and (max-width: 877px) {
  .description {
    width: 100%;
  }
}
