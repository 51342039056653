.form {
  width: 60%;
}

.spinnerWrapper {
  min-width: 200px;
  margin: 0 auto;
}

@media only screen and (max-width: 877px) {
  .form {
    width: 100%;
  }
}

.nameHyError {
  color: red;
  font-size: 12px;
  transition: all 0.3s ease-in;
  padding: 10px 0;
}
