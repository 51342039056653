.wrapper {
  min-height: calc(100vh - 80px);
  padding: 24px;
  background: white;
}

.header {
  max-width: 100%;
  display: flex;
  justify-content: space-between;
  height: 80px;
  padding: 24px;

  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #161616;
  background: #f8f8f8;
}

.icon {
  cursor: pointer;
}
