.defaultFormWrapper {
  /* max-width: 557px; */
}

.defaultFormWrapper:nth-child(n + 2) {
  margin-top: 24px;
}

.formWrapperHeader {
  background: #eaecee;
  border-radius: 8px 8px 0px 0px;
  height: 48;
  padding: 15px 24px;
}

.formWrapperTitle {
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;

  color: #161616;
  opacity: 0.6;
}

.formBody {
  padding: 24px;
  background: #ffffff;
  border-radius: 8px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
