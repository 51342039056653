.fullName {
  font-family: 'Barlow';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #161616;
  display: flex;
  flex-wrap: nowrap;
  width: max-content;
}

.userName {
  font-family: 'Barlow';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #161616;
  opacity: 0.5;
}

.title {
  display: flex;
  align-items: flex-start;
  font-family: 'Barlow';
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 150%;
  align-items: center;
  text-transform: uppercase;
  color: #161616;
  opacity: 0.6;
}

.overwrite::before {
  content: none !important;
  position: initial !important;
}

.teamName {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #161616;
}
