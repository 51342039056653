.wrapper {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: left;
  align-items: center;
  overflow: hidden;
  background-color: #fff;
}

.info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 530px;
  height: 100vh;
}

.info .title {
  margin: 0;
  margin-top: 100px;
  color: #fff;
  font-weight: 500;
  font-size: 28px;
  line-height: 34px;
  text-align: center;
}

.info .text {
  margin: 0;
  margin-top: 8px;
  color: #fff;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
}

.loginTitle {
  font-weight: 600;
  font-size: 30px;
  line-height: 36px;

  color: #161616;
}

.loginSubtitle {
  font-size: 16px;
  line-height: 19px;
  color: #707071;
}

.passwordSubtitle {
  cursor: pointer;
  font-size: 10px;
  line-height: 12px;
  text-align: right;
  color: #615293;
  padding-right: 3px;
}

.loginTitle,
.loginSubtitle {
  text-align: center;
}

.form {
  display: flex;
  flex-direction: column;
  margin-top: 60px;
}

.login {
  width: 30%;
  max-width: 640px;
  margin: 0 auto;
  background-color: #ffffff;
}

.button {
  width: 100%;
  margin-top: 1rem;
}

@media only screen and (min-width: 640px) and (max-width: 768px) {
  .info {
    display: none;
  }
  .login {
    width: 60%;
  }
}

@media screen and (max-width: 640px) {
  .info {
    display: none;
  }
  .login {
    width: 80%;
  }
}
