.menu {
    background: transparent;
    border-radius: 10px;
    padding: 0;
    width: max-content;
}

.menuButton {
    border: 0;
    height: auto;
    padding: 10px;
    text-align: left;
    width: 100%;
}

.lastMenuButton {
    border: 0;
    border-radius: 0 0 10px 10px;
    height: auto;
    margin-top: 2px;
    padding: 10px;
    text-align: left;
    width: 100%;
}
