.wrapper {
    display: flex;
    flex-direction: column;
    max-width: 200px;
}

.title {
    width: 200px;
    padding-left: 16px;

    font-size: 10px;
    line-height: 12px;
    text-transform: uppercase;
    background: #fff;
    color: #161616;
    opacity: 0.6;
    height: 47px;
}

.item {
    position: relative;
    width: 100%;
    padding: 16px;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    background: #ffffff;
    cursor: pointer;
}

.item:hover,
.item.active {
    background: #5451ff;
}

.item:hover .itemText,
.item.active .itemText {
    color: #ffffff;
}

.item.active::after {
    content: '';
    position: absolute;
    right: -4px;
    top: 40%;
    width: 8px;
    height: 8px;

    background-color: #5451ff;
    transform: rotate(-45deg);
}

.dropDown {
    width: 130px;
    z-index: 1000;
    right: 8px;
    top: 37px;
    position: absolute;
    background: #ffffff;
    border-radius: 4px;
    overflow: hidden;
    filter: drop-shadow(0px 4px 6px rgba(0, 0, 0, 0.05));
}

.dropDownItem:nth-child(2) {
    color: #5451ff;
}

.dropDownItem:nth-child(3) {
    color: #ff728b;
}

.dropDownItem {
    width: 100%;
    padding: 9px;
    margin: 0;
    font-size: 12px;
    line-height: 14px;
}

.dropDownItem:hover {
    background-color: #fafafa;
}
