.searchWrapper {
  position: relative;
  width: 318px;
}

.icon {
  position: absolute;
  left: 17px;
  top: 16px;
  cursor: pointer;
}

.search {
  max-height: 46px;
  width: 100%;
  padding: 16px;
  padding-left: 41px;

  border: 1px solid #ededed;
  border-radius: 4px;
}

@media screen and (max-width: 380px) {
  .searchWrapper {
    width: 79vw
  }
}
