.agreementItem {
  position: relative;
}

.page {
  max-width: 300px;
  max-height: 388px;
  border: 1px solid #c4c4c4;
  border-radius: 10px;
  padding: 5px;
}

.documentWrapper {
  cursor: pointer;
}

.agreementItem:hover > .hoverContent {
  visibility: visible;
  height: 100%;
}

.wrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 8px;
}

.agreementButton {
  border: 0;
}

.hoverContent {
  position: absolute;
  display: flex;
  width: 100%;
  visibility: hidden;
  align-items: center;
  justify-content: center;
  height: 0;
  transform: translateY(-50%);
  border-radius: 10px;
  top: 50%;
  background: rgba(15, 23, 46, 0.15);
  transition: 100ms;
}

.errorContentPage {
  composes: page;
  align-self: stretch;
  width: 256px;
  height: 327px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.agreementButtonWrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 16px;
}

.agreementButton {
  width: 200px;
}

@media screen and (max-width: 877px) {
  .hoverContent {
    visibility: visible;
    height: 100%;
  }

  .agreementButtonWrapper {
    justify-content: center;
  }

  .agreementButton {
    width: 256px;
  }

  .wrapper {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .errorContentPage {
    align-self: center;
  }
}

.addAgreement {
  min-width: 255px;
  min-height: 328px;
  border: 1px solid #5552ff;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.hintMassage {
  padding-top: 6px;
  color: #5552ff;
}

.addAgreement:hover {
  background: #e1e1fc;
}
