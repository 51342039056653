.feedbackItemWrapper {
  background-color: #FAFAFA;
  border-radius: 20px;
  margin: 12px 0;
  padding: 24px
}

.feedbackItemResponsive {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
}

.feedbackItemUser {
  display: flex;
  align-items: center;
  flex-direction: row;
}

.contentWrapper {
  width: 60%;
}


@media screen and (max-width: 877px) {
  .feedbackItemResponsive {
    align-items: flex-start;
    flex-direction: column;
  }

  .contentWrapper {
    margin-top: 8px;
    width: 100%;
  }
}
