@import url("./styles/variables.css");

* {
  box-sizing: border-box;
}

html,
body {
  height: auto !important;
}

body {
  margin: 0;
  font-family: "Barlow", sans-serif !important;
  background-color: #f8f8f8 !important;
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
  monospace;
}

.ant-picker-calendar-header .ant-radio-group {
  display: none;
}

.ant-cascader-menus,
.ant-select-dropdown,
.ant-notification,
.ant-picker-dropdown {
  z-index: 1000000 !important;
}

.ant-table,
.ant-table-content table {
  overflow: auto !important;
}

.ant-picker-input > input {
  font-size: 13px !important;
}

.ck-content {
  height: 150px;
}

.error {
  color: red;
  font-size: 12px;
}

.deactivated-avatar {
  pointer-events: none;
  opacity: .4;
}
