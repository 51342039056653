.stateRenderer {
  width: 40px;
  height: 40px;
  color: white;
  border-radius: 50%;
  position: relative;
  text-align: center;
}

.statesWrapper {
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  margin: 0 auto;
  position: relative;
  top: -21px;
}

.stateText {
  color: #86898D;
  text-align: center;
  transition: 0.2s;
}

.line {
  width: 100%;
  position: absolute;
  top: 20px;
  transition: 0.2s;
}

.lineFilled {
  border: 3px solid var(--custom-primary-color);
  top: 18px;
}

.lineEmpty {
  border: 1px solid #C4C4C4;
}

.filled {
  background-color: var(--custom-primary-color);
}

.empty {
  background-color: #C4C4C4;
}

.text {
  text-align: center;
  transition: 0.2s;
}

.textFilled {
  color: var(--custom-primary-color);
}

.textEmpty {
  color: #86898D;
}

.stateRendererItem {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  z-index: 99;
  position: relative;
}

.processContainer {
  width: 100%;
  margin-top: 24px;
}

.stateValueWrapper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.stateRendererItem:last-child .line {
  border-radius: 0 3px 3px 0;
}

.stateRendererItem:first-child .line {
  border-radius: 3px 0 0 3px;
}

@media screen and  (max-width: 877px) {
  .statesWrapper {
    width: 100%;
    justify-content: space-between;
  }

  .stateRendererItem:first-child .line {
    transform: translateX(25%) scaleX(0.5);
  }

  .stateRendererItem:last-child .line {
    transform: translateX(-25%) scaleX(0.5);
  }
  
  .stateRendererItem:last-child .stateValueWrapper {
    align-items: flex-end;
  }


  .stateRendererItem:first-child .stateValueWrapper {
    align-items: flex-start;
  }

  .processContainer {
    margin: 24px auto 0 auto;
  }
}
