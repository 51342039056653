.widget {
  width: 200px;
  height: 90px;
  border-radius: 10px;
  padding: 0 18px;
  cursor: pointer;
}

.title {
  display: flex;
  align-items: center;
  margin: 10px;
  font-size: 20px;
  line-height: 12px;
  font-weight: 500;
  color: white;
}
