.optionsWrapper {
  position: relative;
}

.wrapper {
  display: flex;
  flex-direction: column;
}

.input {
  width: 100%;
  max-height: 48px;
  padding: 14px 16px;
  margin-bottom: 12px;
  background: #ffffff;
  border: 1px solid #ededed;
  border-radius: 4px;
}

.label {
  margin-bottom: 8px;
  font-size: 14px;
  line-height: 20px;
}

.inputWrapper {
  cursor: pointer;
}

.icon {
  position: absolute;
  right: 11px;
  top: 45%;
  cursor: pointer;
}

.dropdownItem:hover {
  color: #5451ff;
}

.dropdownItem {
  width: 100%;
  margin: 0;
  padding: 16px 0;
  border-bottom: 1px solid #ededed;
  cursor: pointer;
}

.datePicker {
  border-radius: 6px;
  width: 100%;
  height: 45px;
  border-color: rgba(0, 0, 0, 0.349) !important;
}

.datePicker:hover {
  border-color: rgba(0, 0, 0, 0.6) !important;
  box-shadow: #1890ff;
}
