.wrapper {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: left;
  align-items: center;
  overflow: hidden;
  background-color: #fff;
}

.title {
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  text-align: center;
  color: #161616;
}

.subtitle {
  font-size: 16px;
  line-height: 19px;
  color: #707071;
  text-align: center;
}

.form {
  display: flex;
  flex-direction: column;
  margin-top: 60px;
}

.formWrapper {
  width: 30%;
  max-width: 640px;
  margin: 0 auto;
  background: #d3d3d324;
  padding: 20px;
}

.button {
  width: 100%;
  margin-top: 1rem;
}

.link {
  width: 100%;
  margin-top: 2rem;
  text-align: center;
  text-decoration: underline;
  display: block;
}

@media only screen and (min-width: 640px) and (max-width: 768px) {
  .formWrapper {
    width: 60%;
  }
}

@media screen and (max-width: 640px) {
  .formWrapper {
    width: 80%;
  }
}
