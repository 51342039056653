.wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.input {
  width: 100%;
  margin-bottom: 12px;
  padding: 14px 16px;
  line-height: 1.5rem;
  height: calc(7 * 1.5rem);
  background: #ffffff;
  border: 1px solid #ededed;
  border-radius: 4px;
  resize: none;
  overflow-x: hidden;
  font-family: Tahoma, Verdana, sans-serif;
}

.highlightingContainer {
  overflow-y: auto;
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 0;
  background: transparent;
}

.textarea {
  display: block;
}

.mark {
  padding: 0;
  background-color: greenyellow;
  color: white;
}


.label {
  margin-bottom: 8px;
  font-size: 14px;
  line-height: 20px;
}
