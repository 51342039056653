.reminderHeader {
    background: #F8F8F8;
    padding: 20px 24px;
}

.reminderModalWrapper {
    background: white;
    border-radius: 10px;
    box-shadow: 0 0 12px rgba(0, 0, 0, 0.1);
    position: fixed;
    right: 16px;
    top: 80px;
    width: 25vw;
    z-index: 20;
}

.reminderItem {
    padding: 8px 16px;
    transition: .4s;
}

.activeReminderItem {
    padding: 8px 16px;
    transition: .4s;
}


.reminderItem:hover {
    border: 1px solid #5451FF;
}

.activeReminderItem:hover {
    border: 1px solid green;
}

.reminderActions {
    max-height: 0;
    opacity: 0;
    overflow: hidden;
    transition: all 0.3s ease-in-out;

}

.reminderItem:hover > .reminderActions {
    max-height: 200px;
    opacity: 1;
}

.activeReminderItem:hover > .reminderActions {
    max-height: 200px;
    opacity: 1;
}

@media (max-width: 992px) {
  .reminderModalWrapper {
    width: 90%;
  }
}
