* {
  transition: all 0.3s ease-in;
}

.panel {
  border-bottom: 1px solid rgba(15, 23, 46, 0.1);
  padding: 16px 0;
}

.showBtnContent {
  margin: 0;
  font-family: 'Barlow';
  font-style: italic;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #5451FF;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.content {
}
