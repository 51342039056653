.wrapper {
  display: flex;
  align-items: center;
  width: max-content;
  min-height: 22px;
  gap: 11px;
  padding: 4px;
}

.btn {
  border: none ;
  font-family: 'Barlow';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  text-decoration-line: underline;
  color: #5451FF;
  cursor: pointer;
  background-color: #FFFFFF;
}
