.drawerContainerWrapper {
  width: 100%;
  background: rgba(17, 17, 17, 0.93) none repeat scroll 0 0;
  position: fixed;
  overflow-x: hidden;
  overflow-y: scroll;
  top: 0;
  bottom: 0;
  animation: toDark 0.5s;
  z-index: 10;
}

.drawerContainerChildrenWrapperFromLeft {
  position: fixed;
  max-width: 40%;
  top: 0;
  overflow-y: scroll;
  left: 0;
  bottom: 0;
  background: white;
  animation: swapFromLeft 0.5s;
}

.drawerContainerChildrenWrapperFromLeft:after,
.drawerContainerChildrenWrapperFromRight:after,
.drawerContainerWrapper:after {
  content: '';
  height: 50px;
  display: block;
}

.drawerContainerChildrenWrapperFromRight {
  position: fixed;
  max-width: 40%;
  overflow-y: scroll;
  top: 0;
  bottom: 0;
  right: 0;
  background: white;
  animation: swapFromRight 0.5s;
}

@keyframes toDark {
  0% {
    background: rgb(105, 105, 105);
  }
  100% {
    background: rgba(17, 17, 17, 0.93) none repeat scroll 0 0;
  }
}

@keyframes swapFromRight {
  0% {
    right: -200px;
  }
  100% {
    right: 0;
  }
}

@keyframes swapFromLeft {
  0% {
    left: -200px;
  }
  100% {
    left: 0;
  }
}

@media screen and (max-width: 877px) {
  .drawerContainerChildrenWrapperFromLeft {
    max-width: 100%;
    width: 100%;
  }

  .drawerContainerChildrenWrapperFromRight {
    max-width: 100%;
    width: 100%;
  }
}
