.badge {
  width: 130px;
  height: 48px;
  border-radius: 4px;
  padding: 0 16px;
  cursor: pointer;
}

.title {
  display: flex;
  align-items: center;
  height: 30px;
  margin: 0;
  font-size: 10px;
  line-height: 12px;
  font-weight: bold;

  text-transform: uppercase;

  color: #ffffff;
}

.statistics {
  display: flex;
  align-items: center;
  height: 30px;
  margin: 0;
  font-weight: 600;
  font-size: 30px;
  line-height: 36px;

  text-align: center;

  color: #ffffff;
}

.blue {
  background: #82c3ff;
}

.pink {
  background: #f58b84;
}

.green {
  background: #99df83;
}

.lilac {
  background: #aa82ff;
}
