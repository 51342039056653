.separatorWrapper {
    align-items: center;
    display: flex;
    justify-content: center;
    margin: 0 16px;
}

.separator {
    background: #F6F6F8;
    height: 20px;
    width: 1px;
}
