.wrapper {
  width: 100%;

}

.headWrapper {
  position: sticky;
  display: grid;
  border-radius: 10px;
  padding: 12px 24px;
  background-color: #FAFAFA;
}

.headRow {
  color: #86898D;
}

.bodyRowWrapper {
  display: grid;
  padding: 16px 24px;
  margin: 6px 0;
  background: #FAFAFA;
  border-radius: 10px;
  position: relative;
}

.rowActionWrapper {
  position: absolute;
  right: 24px;
  top: 50%;
  transform: translateY(-50%);
}

.rowDataTitle {
  display: none;
}

@media screen and (max-width: 877px) {
  .bodyRow {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    margin: 6px 0 4px 0;
  }

  .bodyRow:last-child {
    border-bottom: none;
  }

  .headWrapper {
    display: none;
  }

  .bodyRowWrapper {
    display: flex;
    flex-direction: column;
  }

  .rowDataTitle {
    display: block;
    width: 50%;
    color: #86898D;
  }

  .rowActionWrapper {
    position: relative;
    right: 24px;
    left: 0;
    top: 12px;
    height: min-content;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
}
