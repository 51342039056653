.paymentActionButton {
  width: auto;
  height: auto;
  border: 0;
  padding: 5px;
}

.paymentDownloadButton:hover {
  border: 0;
  background-color: rgba(84, 81, 255, 0.1)
}

.paymentAttachButton:hover {
  border: 0;
  background-color: rgba(139, 209, 51, 0.1)
}

@media screen and (max-width: 877px) {
  .paymentDownloadButton {
    border: 0;
    color: rgba(84, 81, 255);
    text-decoration-line: underline;
  }

  .paymentAttachButton {
    border: 0;
    color: rgba(139, 209, 51);
    text-decoration-line: underline;
  }

  .paymentDownloadButton:hover {
    border: 0;
    background-color: transparent;
  }

  .paymentAttachButton:hover {
    border: 0;
    background-color: transparent;
  }

}
