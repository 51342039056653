.menu-button {
  display: none;
}

.avatar-wrapper {
  margin-right: 7px;
  padding: 0;
  width: auto;
  pointer-events: none;
}

.profile-information-responsive {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0 33px;
}


.ant-layout-sider {
  flex: 0 0 351px !important;
  max-width: 400px !important;
  min-width: 200px !important;
  width: 351px !important;
}

#components-layout-demo-custom-trigger .trigger {
  font-size: 18px;
  padding: 0 24px;
  cursor: pointer;
  transition: color 0.3s;
}


#components-layout-demo-custom-trigger .trigger:hover {
  color: #1890ff;
}

#components-layout-demo-custom-trigger .logo {
  height: 32px;
  background: rgba(255, 255, 255, 0.3);
  margin: 16px;
}

.site-layout .site-layout-background {
  display: flex;
  align-items: center;
  height: 72px;
  background: #fff;
}

.layout {
  height: 100vh;
}

.shared-nav-link {
  padding: 7px 16px;
  border-radius: 2px;
  font-size: 16px;
  line-height: 19px;
  color: #000;
}

.shared-nav-link:hover,
.shared-nav-link-active {
  color: #1890ff;
}

.menu-subtitle {
  font-size: 10px;
  line-height: 12px;
  text-transform: uppercase;
  color: #1a1a1a;
  opacity: 0.3;
  margin: 30px 0 8px 22px;
}

.shared-nav-link {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 15px 20px;
  font-size: 16px;
  line-height: 19px;
  height: 56px;
  color: #86898d;
}

.shared-nav-link:hover,
.shared-nav-link.active {
  color: #5451ff !important;
}

.shared-nav-link:hover:before,
.shared-nav-link.active:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 2px;
  height: 56px;
  border-radius: 40px;
  background: #5451ff;
}

.shared-nav-link-not-working {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 15px 20px;
  font-size: 16px;
  pointer-events: none;
  line-height: 19px;
  height: 56px;
  color: rgba(134, 137, 141, 0.5);
}

.dropdown-link {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  min-height: 56px;
  height: auto;
}

.dropdown-nav {
  position: relative;
  display: flex;
  flex-direction: column;
  margin-top: 30px;
  margin-left: 50px;
}

.dropdown-nav-item {
  position: relative;
  padding: 7px 23px;
  font-size: 16px;
  line-height: 19px;
  border-radius: 2px;
  color: #1a1a1a;
}

.dropdown-nav-item::before {
  content: "";
  position: absolute;
  left: 0;
  top: calc(50% - 3px);
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: rgba(97, 82, 147, 0.3);
}

.dropdown-nav-item:hover {
  color: #5451ff;
}

.dropdown-nav-item:hover::before,
.dropdown-nav-item.active::before {
  background: #5451ff;
}

.shared-main-nav-wrapper {
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 250px;
  width: max-content;
  height: calc(100vh - 50px);
  margin: 24px 0 24px 24px;
  overflow-y: auto;
  overflow-x: hidden;
  background-color: #fff;
  border-radius: 20px;
}

.page-title {
  margin: 0;
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;
  color: #1a1a1a;
}

.nav-bar-btn {
  margin-top: 20px;
}

.children-wrapper {
  margin-top: 24px;
  width: 100%;
  background: white;
  height: calc(100vh - 156px);
  overflow-y: auto;
  overflow-x: hidden;
  border-radius: 20px;
  padding: 16px;
  box-sizing: border-box
}

.topbar-and-children-wrapper {
  width: 100%;
  margin: 24px 17px;
  overflow: hidden;
}

.topbar-wrapper {
  width: 100%;
  padding: 17px 16px;
  background-color: #fff;
  border-radius: 20px
}



