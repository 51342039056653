.filter {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 46px;
    height: 46px;
    background: #ffffff;
    border: 1px solid #ededed;
    border-radius: 4px;
    cursor: pointer;
}

.filter:hover {
    border: 1px solid #ddd;
}
