.scrollContainer {
  overflow: hidden;
  width: 100%;
  height: 40px;
  position: relative;
  min-width: 160px;
  background-color: #fbfbff;
  border-left: 0.5px solid #f5f5fb;
  border-right: 0.5px solid #f5f5fb;
}

.scrollText {
  top: 25%;
  left: 0;
  position: absolute;

  width: max-content;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #b1b1b2;

  -moz-transform: translateX(100%);
  -webkit-transform: translateX(100%);
  transform: translateX(100%);

  -moz-animation: myAnimation 15s linear infinite;
  -webkit-animation: myAnimation 15s linear infinite;
  animation: myAnimation 15s linear infinite;
}

/* for Firefox */
@-moz-keyframes myAnimation {
  from { -moz-transform: translateX(100%); }
  to { -moz-transform: translateX(-100%); }
}

/* for Chrome */
@-webkit-keyframes myAnimation {
  from { -webkit-transform: translateX(100%); }
  to { -webkit-transform: translateX(-100%); }
}

@keyframes myAnimation {
  from {
    -moz-transform: translateX(100%);
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
  }
  to {
    -moz-transform: translateX(-100%);
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
  }
}
