.submit {
  max-width: 200px;
  width: 100%;
  transition: 30ms ease-out;
}

.buttonPosition1 {
  cursor: default;
  transform: translateY(-50px);
}

.buttonPosition2 {
  cursor: default;
  transform: translateY(0);
}

